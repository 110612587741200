import { Col, Row } from 'react-grid-system'
import * as React from 'react'

import Card from '@components/Card'
import Layout from '@components/Layout'
import SEO from '@components/SEO'
import { StaticQuery, graphql } from 'gatsby'

interface QueryResult {
  contentfulConfig: {
    titleWithoutName: string
  }
}

const DocsPage = () => (
  <StaticQuery
    query={graphql`
      query DocsConfigQuery {
        contentfulConfig {
          titleWithoutName
        }
      }
    `}
    render={({ contentfulConfig }: QueryResult) => (
      <Layout title="Docs">
        <SEO title="Docs" />

        <Row>
          <Col sm={4}>
            <Card
              title="Setting up notices"
              description="In Contentful, create a new Notice entry. The Message field determines what will show up in the preview on the dashboard. If you would like the message to link to a modal with more information, fill out the `Modal content` field as well, using Markdown formatting."
              bottomMargin={true}
            />

            <Card
              title="Making the dashboard your default new tab page"
              description="Chrome: Install an extension such as 'Custom New Tab URL', and set the URL to 'https://dashboard.on-running.com'. Safari: Open preferences, and set 'General > New tabs open with' to 'Homepage', and set the 'Homepage' field to 'https://dashboard.on-running.com'. Firefox: Install an extension such as 'New Tab Override', and set the URL to 'https://dashboard.on-running.com'."
              bottomMargin={true}
            />
          </Col>

          <Col sm={4}>
            <Card
              title="Updating tool names and descriptions"
              description="Find the given 'Internal tool' record in Contentful, and make changes as needed. Then, make sure to push the 'Publish' button. The tool will be updated in the dashboard in a few minutes."
              bottomMargin={true}
            />

            <Card
              title="Changing the header text"
              description={`To change the text in the header ("${contentfulConfig.titleWithoutName}"), find the Config content type in Contentful. There should be only one record. Edit it, and change the 'Title (with name)' and 'Title (without name)' fields. Instructions for how to set these fields is in their help text.`}
              bottomMargin={true}
            />
          </Col>

          <Col sm={4}>
            <Card
              title="Arranging internal tools on the dashboard"
              description="To change the order of internal tools, edit the 'Config' content type in Contentful and drag the given tool to the desired position using the handle at the left of the link."
              bottomMargin={true}
            />

            <Card
              title="Setting up background images"
              description="Background images are saved in Contentful as 'Background image set' entries. To add background images, create a new background image set entry, and upload all images needed for that set. Then, enter the start and end time (in 24-hour 'HH:MM' format) to determine when that image is eligible to be shown. Images from a given time slot will be chosen at random when a user loads the dashboard. Lastly, publish the entry, and it's good to go! (Note that you can add multiple images to a single set, so you can also edit existing background image sets.)"
            />
          </Col>
        </Row>
      </Layout>
    )}
  />
)

export default DocsPage

import styled from 'styled-components'

import { FontWeight } from '@lib/typography'

export const Section = styled.section<{ bottomMargin?: boolean }>`
  background: white;
  padding: 0.75rem 1rem 1rem;

  ${props => props.bottomMargin && `margin-bottom: 0.5rem;`}
`

export const H3 = styled.h3`
  font-family: 'Neutraface Slab';
  font-weight: ${FontWeight.Book};
  font-size: 1.25rem;
  line-height: 1.5rem;
`

export const P = styled.p`
  color: rgba(0, 0, 0, 0.6);
  font-size: 0.75rem;
  line-height: 1rem;

  margin-top: 0.5rem;
`

import * as React from 'react'

import * as Styles from './styles'

interface Props {
  title: string
  description?: string
  className?: string
  bottomMargin?: boolean
  onClick?: () => void
}

const Card = ({ title, description, className, bottomMargin, onClick }: Props) => (
  <Styles.Section onClick={onClick} className={className} bottomMargin={bottomMargin}>
    <Styles.H3>{title}</Styles.H3>
    {description && <Styles.P>{description}</Styles.P>}
  </Styles.Section>
)

export default Card
